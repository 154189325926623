<template>
  <div>
    <div class="py-3 pl-3">
      <b-overlay
        :show="showLoading"
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
      />
      <div class="h4 mb-2">
        Модели
      </div>
      <ul
        v-if="models.length"
        class="subitems-list"
      >
        <li
          v-for="model in models"
          :key="model.id"
        >
          <div class="subitem">
            <div class="subitem__content">
              <div class="subitem__title">
                <div class="row align-items-center">
                  <div class="col-3">
                    🇬🇧 {{ model.name }}
                  </div>
                  <div class="col-3">
                    🇷🇺 {{ model.name_rus || '—' }}
                  </div>
                  <div class="col-2">
                    <code>{{ model.slug }}</code>
                  </div>
                  <div class="col-2">
                    <div
                      class="d-flex align-items-center"
                      @click.stop
                    >
                      <b-form-checkbox
                        :checked="model.is_hidden"
                        @change="updateHidden(model)"
                      >
                        Скрыто
                      </b-form-checkbox>
                    </div>
                  </div>
                  <div class="col-2">
                    <div
                      class="d-flex align-items-center"
                      @click.stop
                    >
                      <b-form-checkbox
                        :checked="model.is_popular"
                        @change="updatePopular(model)"
                      >
                        Популярно
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="subitem__actions">
              <b-button
                variant="outline-primary"
                class="btn-icon mr-1"
                @click.stop="editedModel = model.id"
              >
                <feather-icon
                  icon="EditIcon"
                  size="12"
                />
              </b-button>
              <b-button
                variant="outline-danger"
                class="btn-icon"
                @click.stop="deletedModel = model.id"
              >
                <feather-icon
                  icon="Trash2Icon"
                  size="12"
                />
              </b-button>
            </div>
          </div>
        </li>
      </ul>
      <div v-else>
        У этого бренда пока нет моделей
      </div>
      <b-button
        class="mt-2"
        variant="outline-primary"
        @click.prevent="showAddModal = true"
      >
        Добавить модель
      </b-button>
    </div>
    <add-model-popup
      v-if="showAddModal"
      :brand-id="brandId"
      @added="addModel"
      @hidden="showAddModal = false"
    />
    <edit-model-popup
      v-if="editedModel"
      :brand-id="brandId"
      :model-id="editedModel"
      @updated="updateModel"
      @hidden="editedModel = null"
    />
    <delete-model-popup
      v-if="deletedModel"
      :brand-id="brandId"
      :model-id="deletedModel"
      @deleted="deleteModel"
      @hidden="deletedModel = null"
    />
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BOverlay, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import AdminModel from '@/api/http/models/admin/model/AdminModel'
import AddModelPopup from './AddModelPopup.vue'
import EditModelPopup from './EditModelPopup.vue'
import DeleteModelPopup from './DeleteModelPopup.vue'

export default {
  components: {
    BOverlay,
    BButton,
    BFormCheckbox,
    AddModelPopup,
    EditModelPopup,
    DeleteModelPopup,
  },
  props: {
    brandId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showLoading: true,
      showAddModal: false,
      models: [],
      editedModel: null,
      deletedModel: null,
    }
  },
  async created() {
    const response = await AdminModel.getAll(this.brandId)

    if (response.data) {
      this.showLoading = false
      this.models = response.data
    }
  },
  methods: {
    addModel(model) {
      this.models.push(model)
    },
    updateModel(model) {
      const index = this.models.findIndex(el => el.id === model.id)
      if (index !== -1) {
        this.models.splice(index, 1, model)
      }
    },
    deleteModel(modelId) {
      const index = this.models.findIndex(el => el.id === modelId)
      if (index !== -1) {
        this.models.splice(index, 1)
      }
    },
    async updatePopular(model) {
      const response = await AdminModel.update(this.brandId, model.id, {
        ...model,
        ...{ is_popular: !model.is_popular },
      })

      if (response.status === 'success' && response.data) {
        this.updateModel(response.data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `У модели «${model.name}» обновлена популярность`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка при обновлении',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async updateHidden(model) {
      const response = await AdminModel.update(this.brandId, model.id, {
        ...model,
        ...{ is_hidden: !model.is_hidden },
      })

      if (response.status === 'success' && response.data) {
        this.updateModel(response.data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `У модели «${model.name}» обновлена скрытость`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка при обновлении',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>
