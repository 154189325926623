<template>
  <b-overlay
    variant="white"
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-card
      v-if="!showLoading"
    >
      <div class="row mb-1">
        <div class="col-12 col-md-6 col-lg-4">
          <label
            for="filter"
          >
            Фильтр:
          </label>
          <v-select
            id="filter"
            v-model="filter"
            class="w-100"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :clearable="false"
            label="label"
            :options="filterOptions"
            :reduce="option => option.value"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <label
            for="search"
          >
            Поиск:
          </label>
          <b-form-input
            id="search"
            v-model="search"
          />
        </div>
      </div>

      <div
        class="accordion"
        role="tablist"
      >
        <div
          v-for="brand in searchedBrands"
          :key="brand.id"
          class="accordion__item"
        >
          <div
            v-b-toggle="`accordion-${brand.id}`"
            role="tab"
            class="accordion-label"
          >
            <div class="accordion-label__content">
              <div class="row align-items-center w-100">
                <div class="col-12 col-lg-3">
                  <div class="d-flex align-items-center">
                    <div class="accordion-label__icon">
                      <img
                        v-if="brand.logo_sm"
                        :src="brand.logo_sm.url"
                        alt="логотип"
                      >
                      <div
                        v-else
                        class="accordion-label__icon-cover"
                      />
                    </div>
                    🇬🇧 {{ brand.name }}
                  </div>
                </div>
                <div class="col-12 col-lg-2">
                  🇷🇺 {{ brand.name_rus || '—' }}
                </div>
                <div class="col-12 col-lg-2">
                  <code>{{ brand.slug }}</code>
                </div>
                <div class="col-12 col-lg-2">
                  <div
                    class="d-flex align-items-center"
                    @click.stop
                  >
                    <b-form-checkbox
                      :checked="brand.is_hidden"
                      @change="updateHidden(brand)"
                    >
                      Скрыто
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="col-12 col-lg-2">
                  <div
                    class="d-flex align-items-center"
                    @click.stop
                  >
                    <b-form-checkbox
                      :checked="brand.is_popular"
                      @change="updatePopular(brand)"
                    >
                      Популярно
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="col-12 col-lg-1">
                  <div class="d-flex justify-content-end">
                    <b-button
                      variant="outline-primary"
                      class="btn-icon"
                      @click.stop="editedBrand = brand.id"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="12"
                      />
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-collapse
            :id="`accordion-${brand.id}`"
            accordion="my-accordion"
            role="tabpanel"
            @show="currentBrand = brand.id"
          >
            <Models
              v-if="currentBrand === brand.id"
              :key="brand.id"
              :brand-id="brand.id"
            />
          </b-collapse>
        </div>
      </div>

      <edit-brand-popup
        v-if="editedBrand"
        :key="editedBrand"
        :brand-id="editedBrand"
        @hidden="editedBrand = null"
        @updated="updateBrand"
      />
    </b-card>
  </b-overlay>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard, BCollapse, VBToggle, BButton, BOverlay, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AdminBrand from '@/api/http/models/admin/brand/AdminBrand'
import Models from '../models/Models.vue'
import EditBrandPopup from './EditBrandPopup.vue'

export default {
  components: {
    BCard,
    BCollapse,
    BButton,
    BOverlay,
    BFormInput,
    BFormCheckbox,
    vSelect,
    Models,
    EditBrandPopup,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      brands: [],
      currentBrand: null,
      editedBrand: null,
      showLoading: true,
      search: '',
      filter: 'show_all',
      filterOptions: [
        {
          label: 'Показать все',
          value: 'show_all',
        },
        {
          label: 'Показать популярные',
          value: 'only_popular',
        },
        {
          label: 'Показать скрытые',
          value: 'only_hidden',
        },
      ],
    }
  },
  computed: {
    filteredBrands() {
      if (this.filter === 'only_popular') {
        return this.brands.filter(brand => brand.is_popular)
      } if (this.filter === 'only_hidden') {
        return this.brands.filter(brand => brand.is_hidden)
      }
      return this.brands
    },
    searchedBrands() {
      return this.filteredBrands.filter(brand => {
        const searchValue = this.search.toLowerCase()
        const name = brand.name && brand.name.toLowerCase().indexOf(searchValue) !== -1
        const nameRus = brand.name_rus && brand.name_rus.toLowerCase().indexOf(searchValue) !== -1
        const slug = brand.slug && brand.slug.toLowerCase().indexOf(searchValue) !== -1
        return name || nameRus || slug
      })
    },
  },
  async created() {
    const response = await AdminBrand.getAll()

    if (response.data) {
      this.brands = response.data
      this.showLoading = false
    }
  },
  methods: {
    updateBrand(brand) {
      const index = this.brands.findIndex(el => el.id === brand.id)
      if (index !== -1) {
        this.brands.splice(index, 1, brand)
      }
    },
    async updatePopular(brand) {
      const response = await AdminBrand.update(brand.id, {
        ...brand,
        ...{ is_popular: !brand.is_popular },
      })

      if (response.status === 'success' && response.data) {
        this.updateBrand(response.data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `У бренда «${brand.name}» обновлена популярность`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка при обновлении',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async updateHidden(brand) {
      const response = await AdminBrand.update(brand.id, {
        ...brand,
        ...{ is_hidden: !brand.is_hidden },
      })

      if (response.status === 'success' && response.data) {
        this.updateBrand(response.data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `У бренда «${brand.name}» обновлена скрытость`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка при обновлении',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
