<template>
  <b-modal
    id="delete-model-popup"
    :visible="visible"
    size="md"
    ok-title="Удалить"
    cancel-title="Отменить"
    ok-variant="danger"
    :title="`Удалить модель «${currentModel.name}»?`"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  />
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AdminModel from '@/api/http/models/admin/model/AdminModel'

export default {
  props: {
    brandId: {
      type: Number,
      required: true,
    },
    modelId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      currentModel: {},
    }
  },
  async created() {
    const response = await AdminModel.getOne(this.brandId, this.modelId)

    if (response.data) {
      this.currentModel = response.data
      this.visible = true
    }
  },
  methods: {
    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await AdminModel.delete(this.brandId, this.modelId)

        if (response.status === 'success') {
          this.$emit('deleted', this.modelId)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Модель «${this.currentModel.name}» удалена`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.$bvModal.hide('delete-model-popup')
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Ошибка при удалении модели «${this.currentModel.name}»`,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>
