<template>
  <b-modal
    id="add-model-popup"
    :visible="visible"
    size="md"
    ok-only
    ok-title="Сохранить"
    title="Добавление модели"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <div class="row">
        <div class="col-12">
          <b-form-group
            label="Название 🇬🇧"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="currentModel.name"
              v-validate="'required'"
              name="name"
            />
            <small
              v-show="errors.has('name')"
              class="text-danger"
            >{{ errors.first('name') }}</small>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group
            label="Название 🇷🇺"
            label-for="name-rus"
          >
            <b-form-input
              id="name-rus"
              v-model="currentModel.name_rus"
              v-validate="'required'"
              name="name-rus"
            />
            <small
              v-show="errors.has('name-rus')"
              class="text-danger"
            >{{ errors.first('name-rus') }}</small>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group
            label="Slug (ЧПУ)"
            label-for="slug"
          >
            <b-form-input
              id="slug"
              v-model="currentModel.slug"
              v-validate="'required'"
              name="slug"
            />
            <small
              v-show="errors.has('slug')"
              class="text-danger"
            >{{ errors.first('slug') }}</small>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group>
            <b-form-checkbox
              id="is-hidden"
              v-model="currentModel.is_hidden"
              name="is-hidden"
            >
              Скрыть
            </b-form-checkbox>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group>
            <b-form-checkbox
              id="is-popular"
              v-model="currentModel.is_popular"
              name="is-popular"
            >
              Отметить как популярное
            </b-form-checkbox>
          </b-form-group>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AdminModel from '@/api/http/models/admin/model/AdminModel'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormCheckbox,
  },
  props: {
    brandId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      currentModel: {
        name: '',
        name_rus: '',
        slug: '',
        is_hidden: false,
        is_popular: false,
      },
    }
  },
  async created() {
    this.visible = true
  },
  methods: {
    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await AdminModel.add(this.brandId, this.currentModel)

        if (response.status === 'success' && response.data) {
          this.$emit('added', response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Модель добавлена',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.$bvModal.hide('add-model-popup')
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при добавлении модели',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>
