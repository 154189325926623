import HTTPService from '@/api/http/HTTPService'
import ModelInterface from './ModelInterface'

export default {
  getAll: (brandId: number) => HTTPService.get(`admin/brand/${brandId}/model`),
  add: (brandId: number, payload: ModelInterface) => HTTPService.post(`admin/brand/${brandId}/model`, payload),
  getOne: (brandId: number, modelId: number) => HTTPService.get(`admin/brand/${brandId}/model/${modelId}`),
  update: (brandId: number, modelId: number, payload: ModelInterface) => HTTPService.put(`admin/brand/${brandId}/model/${modelId}`, payload),
  delete: (brandId: number, modelId: number) => HTTPService.delete(`admin/brand/${brandId}/model/${modelId}`),
}
